import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'

const SoilTestingPage = () => {
  return (
    <Layout pageTitle="Soil Testing">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/lagoon-cleaning-hero.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Soil Testing</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section default-text">
          <Row className="align-items-center" md={2} xs={1}>
            <Col>
              <h3>What is it?</h3>
              <p>
                Soil sampling is a vital part of effective nutrient management in crop production. It is an evaluation tool to assess essential nutrient levels including Phosphorus, Potassium, organic matter, pH, Zinc, Manganese, Sulfur, Magnesium. Collecting this information helps determine the correct nutrient management programs and fertilizer blends for the best rate of return and to preserve the natural environment. We are equipped to provide a variety of soil sampling techniques and recommendations to fit your operation’s needs.
              </p>
            </Col>
            <Col>
              <StaticImage
                src="../../../media/NASM.jpg"
              />
            </Col>
          </Row>
        </Container>
        <Container><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row className="align-items-center" md={2} xs={1}>
            <Col md={{ order: 'first' }} xs={{ order: 'last' }}>
              <StaticImage
                src="../../../media/manure-samples.jpg"
              />
            </Col>
            <Col>
              <h3>Why use manure?</h3>
              <p>
                Manure is an excellent source of many essential plant nutrients and, with proper management, can meet nearly all crop nutrient requirements. Unlike fertilizer, the form of manure and its composition can vary widely causing either nutrient deficiencies resulting in reduced yield or nutrient surpluses that are susceptible to loss through runoff and leaching. Knowing the nutrient content of the manure being applied allows the operator to maximize the economic benefit of the nutrients in crop production, reducing costs, and reducing the potential for negative environmental impacts from excess nutrient application. We are equipped to provide a variety of manure sampling techniques and recommendations to fit your operation’s needs.
              </p>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export default SoilTestingPage